<!-- 联系我们 -->
<template>
  <div class="contactUs">
    <el-row>
      <el-col :span="24" :lg="24">
        <div class="pageBox">
          <div class="header pc">
            <el-image style="width: 100%;" :src="require('../../assets/images/contactUs/banner.png')" :fit="'fill'">
            </el-image>
            <div class="title" :class="{ weibei: lang }">{{ $t('contactUs') }}</div>
          </div>
          <div class="header h5">
            <img src="@/assets/images/contactUs/banner.png" alt="" style="width:auto;height:100%;object-fit: cover;" />
            <div class="title" :class="{ weibei: lang }">{{ $t('contactUs') }}</div>
          </div>
          <div class="lcontainer container flex a-c j-b">
            <div>
              <p class="title" :class="{ weibei: lang }">{{ $t('contactUsp1') }}</p>
              <p class="m-b-14 lineStyle">
                <span class="dot"></span>
                <span class="content" v-html="$t('contactUsp2')"></span>
              </p>
              <p class="m-b-14 lineStyle">
                <span class="dot"></span>
                <span class="content" v-html="$t('contactUsp4')"></span>
              </p>
              <!-- <p class="m-b-14 lineStyle">
                <span class="dot"></span>
                <span class="content" v-html="$t('contactUsp5')"></span>
              </p> -->
              <p class="m-b-14 lineStyle">
                <span class="dot"></span>
                <span class="content" v-html="$t('contactUsp6')"></span>
              </p>
              <p class="m-b-14 lineStyle">
                <span class="dot"></span>
                <span class="content" v-html="$t('contactUsp7')"></span>
              </p>
            </div>
            <div class="pc">
              <el-image style="width: 100%;" :src="require('../../assets/images/contactUs/telicon.png')" :fit="'fill'">
              </el-image>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
export default {
  data() {
    return {

    }
  },
  computed: {
    lang() {
      const localeLang = window.localStorage.getItem("locale") || "zh-CN";
      return localeLang === 'zh-CN';
    },
  },
}
</script>
<style lang="stylus" scoped>
@media screen and (min-width: 728px)
    .contactUs
      background:#fafafa;
      width 100%;
      overflow hidden;
      .pageBox
        .h5 
          display: none
        .header
          position: relative
          overflow hidden
          .title
            position absolute
            top 50%
            left 50%
            color #fff
            padding: 0 20px;
            height 80px
            line-height 80px
            transform: translate(-50%,-50%)
            text-align center;
            background: rgba(141, 198, 63, 0.9);
            font-weight: 700;
            font-size: 42px;
         .container
            width: 100%;
            max-width: 1520px;
            margin: auto;
            padding: 69px 60px 56px 60px;
            text-align: left
            box-sizing: border-box
            .title
              font-style: normal
              font-weight: 700
              font-size: 28px
              line-height: 42px    
              color: #405E3C
              text-align:  left
              margin-bottom: 63px
            .lineStyle
              width: 100%;
              display: flex;
              align-items: flex-start;
              justify-content: flex-start
              .dot
                margin-top: 7px;
              .content
                line-height: 20px;
                font-size: 16px;
@media screen and (max-width: 728px)
    .contactUs
      width 100%;
      .pageBox
        .pc 
          display: none
        .h5
          img
            position: absolute;
            top 0;
            right: 0;
            z-index 1;
        .header
          position: relative
          overflow hidden
          height: 190px
          width:  100%
          .title
            position absolute
            top 50%
            left 50%
            color #fff
            width 164px
            height 60px
            line-height 60px
            transform: translate(-50%,-50%)
            text-align center;
            background: rgba(141, 198, 63, 0.9);
            font-weight: 700;
            font-size: 22px;
            z-index 2;
         .container
            padding: 20px
            margin-top: 20px
            text-align: left
            box-sizing: border-box
            .title
              font-style: normal
              font-weight: 700
              font-size: 20px
              line-height: 30px    
              color: #405E3C
              text-align:  left
              margin-bottom: 30px
            .lineStyle
              width: 100%;
              display: flex;
              align-items: flex-start;
              justify-content: flex-start
              .dot
                margin-top: 7px;
              .content
                line-height: 20px;            


</style>